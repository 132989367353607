import { useAuth } from '@/lib/hooks/use-auth';
import { createContext, useContext, useEffect, useMemo } from 'react';
import userflow from 'userflow.js';
interface UserflowContextValue {
  identify: (userId: string, attributes?: Record<string, any>) => void;
  track: (eventName: string, attributes?: Record<string, any>) => void;
  updateUser: (attributes: Record<string, any>) => void;
  reset: () => void;
}
const UserflowContext = createContext<UserflowContextValue | null>(null);
export interface UserflowProviderProps {
  children: React.ReactNode;
  token: string;
}
export function UserflowProvider({
  children,
  token
}: UserflowProviderProps) {
  const {
    user
  } = useAuth();
  useEffect(() => {
    if (token) {
      userflow.init(token);
    }
  }, [token]);
  useEffect(() => {
    if (user) {
      userflow.identify(user.id, {
        name: user.name,
        email: user.email,
        signed_up_at: user.createdAt
        // Add any other user attributes you want to track
      });
    } else {
      userflow.reset();
    }
    return () => {
      userflow.reset();
    };
  }, [user]);
  useEffect(() => {
    if (token) {
      try {
        console.log('Initializing Userflow with token:', token);
        userflow.init(token);
        console.log('Userflow initialized successfully');
      } catch (error) {
        console.error('Failed to initialize Userflow:', error);
      }
    }
  }, [token]);
  const trackWithErrorHandling = (eventName: string, attributes?: Record<string, any>) => {
    try {
      console.log(`Tracking event: ${eventName}`, attributes);
      userflow.track(eventName, attributes);
      console.log(`Successfully tracked: ${eventName}`);
    } catch (error) {
      console.error(`Failed to track event ${eventName}:`, error);
    }
  };
  const value = useMemo(() => ({
    identify: (userId: string, attributes?: Record<string, any>) => {
      userflow.identify(userId, attributes);
    },
    track: (eventName: string, attributes?: Record<string, any>) => {
      userflow.track(eventName, attributes);
    },
    updateUser: (attributes: Record<string, any>) => {
      userflow.updateUser(attributes);
    },
    reset: () => {
      userflow.reset();
    }
  }), []);
  return <UserflowContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="UserflowProvider" data-sentry-source-file="user-flow-context.tsx">
      {children}
    </UserflowContext.Provider>;
}
export const useUserflow = () => {
  const context = useContext(UserflowContext);
  if (!context) {
    throw new Error('useUserflow must be used within a UserflowProvider');
  }
  return context;
};