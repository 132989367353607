import { useAuth } from '@/lib/hooks/use-auth';
import { useGetStoreSettingsQuery } from '@/lib/state/services/customer-settings-api';
import { useCallback, useState } from 'react';

interface OnboardingNotificationData {
  userName: string;
  email: string;
  companyName?: string | null;
  tier: string;
  storeAddress?: string | null;
  storeAddress2?: string | null;
  storeCity?: string | null;
  storeState?: string | null;
  storeZip?: string | null;
  storePhone?: string | null;
  storeUrl?: string | null;
  storeEmail?: string | null;
}

async function sendOnboardingNotification(
  userData: OnboardingNotificationData
): Promise<boolean> {
  try {
    const response = await fetch('/api/slack/onboarding-complete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...userData,
        completionTime: new Date().toISOString()
      })
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(
        errorData?.message || `Server responded with status: ${response.status}`
      );
    }

    return true;
  } catch (error) {
    console.error('Error sending onboarding notification:', error);
    return false;
  }
}

export function useOnboardingNotification() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const user = useAuth();
  const store_id = user?.user?.store_id;
  const {
    data: storeData,
    isLoading: isStoreLoading,
    error: storeError
  } = useGetStoreSettingsQuery(store_id, {
    // Only fetch if we have a store_id
    skip: !store_id
  });

  const sendNotification = useCallback(
    async (tier: string): Promise<boolean> => {
      if (!user?.user) {
        setError('No user data available');
        return false;
      }

      if (!store_id) {
        setError('No store ID available');
        return false;
      }

      if (isStoreLoading) {
        setError('Store details still loading');
        return false;
      }

      if (storeError) {
        setError('Failed to load store details');
        return false;
      }

      const storeDetails = storeData?.data;
      if (!storeDetails) {
        setError('Store details not available');
        return false;
      }

      setIsLoading(true);
      setError(null);

      try {
        const notificationData: OnboardingNotificationData = {
          userName: user.user.name || 'Unknown User',
          email: user.user.email || 'unknown@email.com',
          companyName: storeDetails.name || null,
          storeAddress: storeDetails.street_address || null,
          storeAddress2: storeDetails.street_address_2 || null,
          storeCity: storeDetails.city || null,
          storeState: storeDetails.state || null,
          storeZip: storeDetails.zipcode || null,
          storePhone: storeDetails.phone || null,
          storeUrl: storeDetails.url || null,
          storeEmail: storeDetails.email_branded || null,
          tier
        };

        const result = await sendOnboardingNotification(notificationData);
        return result;
      } catch (err) {
        setError(
          err instanceof Error ? err.message : 'Failed to send notification'
        );
        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [user, store_id, storeData, isStoreLoading, storeError]
  );

  return {
    sendNotification,
    isLoading,
    error,
    isStoreLoading,
    storeError
  };
}
