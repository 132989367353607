// lib/hooks/use-service-loader.ts
import { useEffect, useState } from 'react';

export type ServiceName =
  | 'userback'
  | 'analytics'
  | 'sentry'
  | 'posthog'
  | 'gtm'
  | 'pusher'
  | 'dateFns'
  | 'emotion'
  | 'userflow';

export const useServiceLoader = (serviceName: ServiceName, enabled = true) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!enabled) return;

    const loadService = async () => {
      try {
        let posthogUtils;
        let gtmModule;
        let gtmConfigModule;

        switch (serviceName) {
          case 'userback':
            await import('@userback/react');
            break;
          case 'analytics':
            await import('@vercel/analytics/react');
            break;
          case 'sentry':
            await import('@sentry/nextjs');
            break;
          case 'posthog':
            posthogUtils = await import('@/lib/utils/posthog');
            await posthogUtils.initializePostHog();
            break;
          case 'gtm':
            gtmModule = await import('@/lib/gtm/gtm');
            gtmConfigModule = await import('@/lib/gtm/config');
            await gtmModule.gtm.initialize(gtmConfigModule.gtmConfig);
            break;
          case 'pusher':
            await import('@/lib/services/pusher-initialize/pusher-initialize');
            break;
          case 'dateFns':
            await import('@mui/x-date-pickers/AdapterDateFns');
            break;
          case 'emotion':
            await import('@emotion/react');
            break;
          default:
            throw new Error(`Unknown service: ${serviceName}`);
        }
        setIsLoaded(true);
      } catch (err) {
        console.error(`Failed to load service ${serviceName}:`, err);
        setError(
          err instanceof Error
            ? err
            : new Error(`Failed to load service ${serviceName}`)
        );
      }
    };

    loadService();
  }, [serviceName, enabled]);

  return { isLoaded, error };
};

export const useMultipleServices = (services: ServiceName[]) => {
  const [serviceStates, setServiceStates] = useState<{
    [key in ServiceName]?: { isLoaded: boolean; error: Error | null };
  }>({});

  useEffect(() => {
    const loadServices = async () => {
      for (const service of services) {
        try {
          let posthogUtils;
          let gtmModule;
          let gtmConfigModule;

          switch (service) {
            case 'userback':
              await import('@userback/react');
              break;
            case 'analytics':
              await import('@vercel/analytics/react');
              break;
            case 'sentry':
              await import('@sentry/nextjs');
              break;
            case 'posthog':
              posthogUtils = await import('@/lib/utils/posthog');
              await posthogUtils.initializePostHog();
              break;
            case 'gtm':
              gtmModule = await import('@/lib/gtm/gtm');
              gtmConfigModule = await import('@/lib/gtm/config');
              await gtmModule.gtm.initialize(gtmConfigModule.gtmConfig);
              break;
            case 'pusher':
              await import(
                '@/lib/services/pusher-initialize/pusher-initialize'
              );
              break;
            case 'dateFns':
              await import('@mui/x-date-pickers/AdapterDateFns');
              break;
            case 'emotion':
              await import('@emotion/react');
              break;
            case 'userflow':
              await import('userflow.js');
              break;
          }

          setServiceStates((prev) => ({
            ...prev,
            [service]: { isLoaded: true, error: null }
          }));
        } catch (err) {
          setServiceStates((prev) => ({
            ...prev,
            [service]: {
              isLoaded: false,
              error:
                err instanceof Error
                  ? err
                  : new Error(`Failed to load service ${service}`)
            }
          }));
        }
      }
    };

    loadServices();
  }, [services]);

  const allLoaded = services.every(
    (service) => serviceStates[service]?.isLoaded
  );
  const errors = services
    .filter((service) => serviceStates[service]?.error)
    .map((service) => ({
      service,
      error: serviceStates[service]?.error as Error
    }));

  return {
    isLoaded: allLoaded,
    errors: errors.length > 0 ? errors : null,
    states: serviceStates
  };
};

export const SERVICE_GROUPS = {
  critical: ['sentry', 'emotion'] as ServiceName[],
  important: ['gtm', 'posthog', 'dateFns', 'userflow'] as ServiceName[],
  nonCritical: ['userback', 'analytics', 'pusher'] as ServiceName[]
} as const;

export const usePrioritizedServices = () => {
  const critical = useMultipleServices(SERVICE_GROUPS.critical);
  const important = useMultipleServices(SERVICE_GROUPS.important);
  const nonCritical = useMultipleServices(SERVICE_GROUPS.nonCritical);

  return {
    criticalReady: critical.isLoaded,
    importantReady: important.isLoaded,
    nonCriticalReady: nonCritical.isLoaded,
    errors: {
      critical: critical.errors,
      important: important.errors,
      nonCritical: nonCritical.errors
    }
  };
};
